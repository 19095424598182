'use client'

import React from 'react'
import { usePathname, useRouter } from 'next/navigation'
import Messages from '@/appcomponents/messages'
import { Line } from '@/ui/components/dashboard/components/line'
import AutoForm, { AutoFormSubmit } from '@/ui/components/ui/auto-form'
import { Button } from '@/ui/components/ui/button'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/ui/components/ui/card'
import { HandIcon, ThumbsUpIcon } from 'lucide-react'
import { useTranslations } from 'next-intl'
import { toast } from 'sonner'
import * as z from 'zod'

import { hasAccountAction } from '@ralph/actions/has-account-action'
import { resetPasswordAction } from '@ralph/actions/reset-password-action'
import { sendMagicLinkAction } from '@ralph/actions/send-magic-link-action'
import { signInAction } from '@ralph/actions/sign-in-action'
import { signUpAction } from '@ralph/actions/sign-up-action'
import { normalizeEmailData } from '@ralph/db/utils/mappers'

export default function Login() {
  const t = useTranslations()
  const router = useRouter()
  const pathname = usePathname()
  // const [isSubmitting, startTransition] = React.useTransition()
  const [email, setEmail] = React.useState<string>()
  const [currentStep, setCurrentStep] = React.useState<
    'email' | 'signup' | 'signin' | 'check-email'
  >('email')

  const EmailSchema = React.useMemo(
    () =>
      z.object({
        email: z.string().email().describe(t('login.email-describe')),
      }),
    [t]
  )

  const SignupSchema = React.useMemo(
    () =>
      z.object({
        password: z.string().min(6).describe(t('account.create-new-password')),
        repeatPassword: z
          .string()
          .describe(t('account.repeat-password'))
          .min(6),
      }),
    [t]
  )

  const SigninSchema = React.useMemo(
    () =>
      z.object({
        password: z.string().min(6).describe(t('login.password-label')),
      }),
    [t]
  )

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  return (
    <div className="m-auto mt-24 flex min-h-[inherit] w-full flex-1 flex-col items-center justify-center gap-2 px-8 sm:max-w-md">
      {currentStep === 'email' && (
        <Card className="z-20 min-w-[365px] sm:min-w-[448px]">
          <CardHeader className="">
            <HandIcon className="animate-wiggle-more animate-twice text-brandSecondary" />
            <CardTitle className="text-2xl font-normal">
              {t('login.welcome')}
            </CardTitle>
            <Line />
          </CardHeader>
          <CardContent>
            <Messages />
            <AutoForm
              className="flex w-full flex-1 flex-col justify-center gap-2 text-foreground animate-in"
              formSchema={EmailSchema}
              dontAddScroll={true}
              fieldConfig={{
                email: {
                  inputProps: {
                    type: 'email',
                    placeholder: t('login.email-placeholder'),
                    defaultValue: email,
                  },
                },
              }}
              onSubmit={(values) => {
                router.push(pathname)
                const newEmail = normalizeEmailData(values.email)!
                setIsSubmitting(true)
                toast.promise(hasAccountAction({ email: newEmail }), {
                  success: (alreadyHasAccount) => {
                    setEmail(newEmail)
                    setCurrentStep(alreadyHasAccount ? 'signin' : 'signup')
                    return alreadyHasAccount
                      ? t('login.has-account-yes')
                      : t('login.has-account-no')
                  },
                  error: (e) => {
                    return e?.message
                  },
                  finally: () => {
                    setIsSubmitting(false)
                  },
                })
              }}
            >
              <AutoFormSubmit isLoading={isSubmitting}>
                {t('login.auth-email-continue')}
              </AutoFormSubmit>
            </AutoForm>
          </CardContent>
        </Card>
      )}
      {currentStep === 'signup' && (
        <Card className="z-20 min-w-[365px] sm:min-w-[448px]">
          <CardHeader className="">
            <HandIcon className="animate-wiggle-more animate-twice text-brandSecondary" />
            <CardTitle className="text-2xl font-normal">
              {t('login.auth-create-password-title')}
            </CardTitle>
            <CardTitle className="text-2xl font-normal">{email}</CardTitle>
            <Line />
            <CardDescription className="">
              {t('login.auth-create-password-description')}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Messages />
            <AutoForm
              className="flex w-full flex-1 flex-col justify-center gap-2 text-foreground animate-in"
              formSchema={SignupSchema}
              fieldConfig={{
                password: {
                  inputProps: {
                    type: 'password',
                    placeholder: t('login.password-label'),
                  },
                },
                repeatPassword: {
                  inputProps: {
                    type: 'password',
                    placeholder: t('login.repeat-password-label'),
                  },
                },
              }}
              onSubmit={(values) => {
                setIsSubmitting(true)
                toast.promise(signUpAction({ email: email!, ...values }), {
                  success: () => {
                    setCurrentStep('check-email')
                    return t('login.sign-up-complete-check-email')
                  },
                  error: (e) => {
                    return e?.message
                  },
                  finally: () => {
                    setIsSubmitting(false)
                  },
                })
              }}
            >
              <AutoFormSubmit isLoading={isSubmitting}>
                {t('login.auth-email-continue')}
              </AutoFormSubmit>
            </AutoForm>
            <div className="my-4 w-full border-b border-cyan-300" />
            <Button
              onClick={() => setCurrentStep('email')}
              className="w-full"
              variant="link"
            >
              {t('login.auth-create-password-back')}
            </Button>
          </CardContent>
        </Card>
      )}
      {currentStep === 'signin' && (
        <Card className="z-20 min-w-[365px] sm:min-w-[448px]">
          <CardHeader className="">
            <HandIcon className="animate-wiggle-more animate-twice text-brandSecondary" />
            <CardTitle className="text-2xl font-normal">
              {t('login.auth-sign-in-title')}
            </CardTitle>
            <CardTitle className="text-2xl font-normal">{email}</CardTitle>
            <Line />
            <CardDescription className="">
              {t('login.auth-sign-in-description')}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Messages />
            <AutoForm
              className="flex w-full flex-1 flex-col justify-center gap-2 text-foreground animate-in"
              formSchema={SigninSchema}
              fieldConfig={{
                password: {
                  inputProps: {
                    type: 'password',
                    placeholder: t('login.password-label'),
                  },
                },
              }}
              onSubmit={(values) => {
                setIsSubmitting(true)
                toast.promise(
                  signInAction({ email: email!, password: values.password }),
                  {
                    success: (r) => {
                      router.push(r)
                      return t('login.sign-in-loading')
                    },
                    error: (e) => {
                      return e?.message
                    },
                    finally: () => {
                      setIsSubmitting(false)
                    },
                  }
                )
              }}
            >
              <AutoFormSubmit disabled={isSubmitting} isLoading={isSubmitting}>
                {t('login.sign-in-button')}
              </AutoFormSubmit>
            </AutoForm>
            <Button
              onClick={() => {
                console.log('send magic link')
                router.push(pathname)
                setIsSubmitting(true)
                toast.promise(sendMagicLinkAction({ email: email! }), {
                  success: () => {
                    setCurrentStep('check-email')
                    return t('login.auth-check-email-description')
                  },
                  error: (e) => {
                    return e?.message
                  },
                  finally: () => {
                    setIsSubmitting(false)
                  },
                })
              }}
              disabled={isSubmitting}
              className="mt-4 w-full"
              variant="outline"
            >
              {t('login.sign-in-with-magic-link')}
            </Button>
            <div className="my-4 w-full border-b border-cyan-300" />
            <Button
              onClick={() => {
                router.push(pathname)
                setIsSubmitting(true)
                toast.promise(resetPasswordAction({ email: email! }), {
                  success: () => {
                    setCurrentStep('check-email')
                    return t('login.auth-check-email-description')
                  },
                  error: (e) => {
                    return e?.message
                  },
                  finally: () => {
                    setIsSubmitting(false)
                  },
                })
              }}
              disabled={isSubmitting}
              className="w-full"
              variant="link"
            >
              {t('login.sign-in-forgot-password')}
            </Button>
            <Button
              onClick={() => setCurrentStep('email')}
              className="w-full"
              variant="link"
            >
              {t('login.auth-create-password-back')}
            </Button>
          </CardContent>
        </Card>
      )}
      {currentStep === 'check-email' && (
        <Card className="z-20 min-w-[365px] sm:min-w-[448px]">
          <CardHeader className="">
            <ThumbsUpIcon className="text-brandSecondary" />
            <CardTitle className="text-2xl font-normal">
              {t('login.auth-check-email-title')}
            </CardTitle>
            <CardTitle className="text-2xl font-normal">{email}</CardTitle>
            <Line />
            <CardDescription className="">
              {t('login.auth-check-email-description')}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="my-4 w-full border-b border-cyan-300" />
          </CardContent>
        </Card>
      )}
    </div>
  )
}
